.container {
    padding: 4.0rem;
    background-color: #f7f7f7;
    /* text-align: center;
    padding: 0 1rem;
    padding-bottom: 4.0rem;
    padding-left: 10.0rem;
    padding-right: 10.0rem; */
    /* background-image: url(../assets/circuits.png); */
}

.title {
    text-align: center;
    padding: 3.5rem;
    font-family: Arial;
    font-weight: bold;
    font-size: 5rem;
    color: #615d6d;
}

.summary {
    display: block;
    margin: 0 auto;
    font-size: 1.5rem;
    text-align: center;
    /* padding: 3.5rem 0; */
    /* max-width: 1200px; */
}



/* Mobile Section*/
@media only screen and (max-width: 650px) {

    .container {
        background-color: #f7f7f7;
        text-align: center;
        padding: 0 1rem;
        padding-right: 1.0rem;
        padding-left: 1.0rem;
        
    }

    .title {
        padding: 3.5rem;
        font-family: Arial;
        font-weight: bold;
        font-size: 2.75rem;
        color: #615d6d;
    }
    
    .summary {
        margin: 0 auto;
        font-size: 1.125rem;
        /* padding: 3.5rem 0; */
        max-width: 1200px;
    }

}




