.container {
    padding: 4.0rem;
    background-color: white;
    /* text-align: center;
    padding: 0 1rem;
    padding-bottom: 4.0rem;
    padding-left: 8.0rem;
    padding-right: 8.0rem; */
}

.title {
    /* padding: 3.5rem; */
    font-family: Arial;
    font-weight: bold;
    font-size: 5rem;
    color: #615d6d;
    text-align: center;
}

.summary {
    text-align: center;
    font-size: 1.5rem;
}

.imageContainer {
    text-align: center;
}




/* Mobile Section*/
@media only screen and (max-width: 650px) {

    .container {
        background-color: white;
        padding: 0 1rem;
        
    }

    .title {
        padding: 3.5rem;
        font-family: Arial;
        font-weight: bold;
        font-size: 2.75rem;
        color: #615d6d;
        text-align: center;
    }

    .comb {
        width: 300px;
    }

}




