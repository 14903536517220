.container {
    background-color: #f7f7f7;
    padding: 4.0rem;
    /* padding: 0 1rem; */
    /* margin-left: 5%; */
    /* margin-right: 5%; */
    /* margin-top: 5%; */
}

.techStackTitle {
    /* margin-top: 2%; */
    /* padding-top: 4.0rem; */
    padding: 4.5rem;
    text-align: center;
    font-family: Arial;
    font-weight: bold;
    font-size: 5rem;
    color: #615d6d;
}

.summary {
    text-align: center;
    font-size: 1.5rem;
}

.stack {

    /* display: flex;
    flex-direction: row;
    padding: 4.5rem; */
    
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 10px 0;
    flex-wrap: wrap;
    column-gap: 20px;

}



/* Mobile Section*/
@media only screen and (max-width: 650px) {

    .container {
        background-color: #f7f7f7;
        padding: 1.0rem;
    }

    .techStackTitle {
        /* margin-top: 2%; */
        /* padding-top: 4.0rem; */
        padding: 3.5rem;
        text-align: center;
        font-family: Arial;
        font-weight: bold;
        font-size: 2.75rem;
        color: #615d6d;
    }

    .stack {
        display: flex;
        flex-direction: row;
        /* padding: 1.0rem; */
        margin-left: 2%;
    }
  
  }