@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.cards {
    margin: 30px auto;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
    -webkit-transition:  box-shadow .2s ease-out;
    box-shadow: .8px .9px 3px grey;
    }

    .cards:hover{ 
         box-shadow: 20px 8px 20px grey;
        -webkit-transition:  box-shadow .2s ease-in;
        opacity: .8;
      }

  .image {
    width: 90%;
    height: 45%;
  } 

  
  /* @media only screen and (max-width: 650px) {
    .cards {
      width: 80%;
      margin: 20px auto;
    }
    .image {
      width: 100%;
      height: 40%;
    }
    .title {
      font-size: 1.0em;
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
    }
    .summary {
      font-size: .8em;
      font-family: 'Open Sans', sans-serif;
    }
  } */