.container {
    background-color: white;
    /* background-color: #E6D1F2; */
    padding: 3.5rem;
    
}

.title {
    padding: 3.5rem;
    text-align: center;
    font-family: Arial;
    font-weight: bold;
    font-size: 5rem;
    color: #615d6d;
}

.cardContainer {
    padding-left: 8rem;
    padding-right: 8rem;
}